<template>
  <div class="pa-3">
    <div class="d-flex justify-space-between align-center pa-3 mb-3">
      <h1>Downloads</h1>
      <v-btn
        depressed
        fab
        small
        dark
        color="green"
        @click="fetchQueue('reload', 360)"
      >
        <v-icon id="reload">mdi-reload</v-icon>
      </v-btn>
    </div>
    <!-- {{ timer }} -->
    <div v-if="queue" class="pa-3">
      <v-card flat class="pa-3 mb-2" v-for="item in queue.data" :key="item.id">
        <v-row>
          <v-col cols="1" md="1">
            <div class="_100h d-flex align-center justify-center">
              <img
                src="../../../assets/img/zip icon 0.png"
                height="50px"
                alt=""
              />
            </div>
          </v-col>
          <v-col cols="7" sm="11" md="7">
            <div class="_100h d-flex flex-column justify-center">
              <div class="pill_date py-2 px-3" style="width: max-content">
                <strong>{{
                  $date(item.created_at).format("DD/MM/YYYY HH:mm")
                }}</strong>
              </div>
              <small class="grey--text"> {{ item.full_path }} </small>
            </div>
          </v-col>
          <template v-if="item.status_archive == 1">
            <v-col cols="4" sm="7" md="2">
              <div class="pill_items pa-1 d-flex align-center mt-2">
                <div class="n_items d-flex justify-center align-center mr-2">
                  <strong>{{ item.item }}</strong>
                </div>
                <p class="ma-0 white--text"><strong>Items</strong></p>
              </div>
            </v-col>
            <v-col cols="4" sm="5" md="2">
              <div class="_100h d-flex align-center justify-center">
                <v-btn
                  depressed
                  fab
                  color="#00897B"
                  dark
                  small
                  @click="downloadZip(item)"
                >
                  <v-icon> mdi-progress-download </v-icon>
                </v-btn>
                <v-btn
                  depressed
                  fab
                  color="red"
                  dark
                  small
                  class="ml-2"
                  @click="showModalDel(item)"
                >
                  <v-icon> mdi-delete </v-icon>
                </v-btn>
              </div>
            </v-col>
          </template>
          <template v-if="item.status_archive == 0">
            <v-col cols="6" md="4">
              <div class="d-flex flex-column justify-center _100h">
                <h3>Archiving...</h3>
                <v-progress-linear
                  indeterminate
                  color="yellow darken-2"
                ></v-progress-linear>
              </div>
            </v-col>
          </template>
          <template v-if="item.status_archive == 2">
            <v-col cols="3" md="2">
              <v-chip class="ma-2 pa-2" color="red" text-color="white">
                <b> Archiving Failed</b>
              </v-chip>
            </v-col>
            <v-col cols="3" md="2">
              <div class="_100h d-flex align-center justify-center">
                <v-btn
                  depressed
                  fab
                  color="red"
                  dark
                  small
                  class="ml-2"
                  @click="showModalDel(item)"
                >
                  <v-icon> mdi-delete </v-icon>
                </v-btn>
              </div>
            </v-col>
          </template>
        </v-row>
      </v-card>
      <div
        class="d-flex justify-center align-center"
        style="height: 50vh"
        v-if="!queue.data.length"
      >
        <div>
          <img
            src="../../../assets/img/empty-trash.svg"
            height="250px"
            alt=""
          />
          <h2 class="text-center"></h2>
        </div>
      </div>
    </div>
    <v-dialog v-model="dialogDelete" persistent max-width="400px">
      <v-card class="rounded-max">
        <v-toolbar dense flat> </v-toolbar>
        <v-container>
          <div class="d-flex flex-column align-center">
            <img
              src="../../../assets/img/delete-icon.png"
              height="150px"
              alt=""
            />
            <h3>Are You Sure?</h3>
          </div>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue"
            class="text-capitalize"
            text
            @click="dialogDelete = false"
          >
            Cancel
          </v-btn>
          <v-btn
            v-if="!loading"
            color="red"
            class="text-capitalize"
            depressed
            dark
            @click="deleteQueue"
            ><v-icon class="mr-2">mdi-delete</v-icon>
            <p class="ma-0">Delete</p></v-btn
          >
          <v-btn
            v-if="loading"
            color="red"
            class="text-capitalize"
            dark
            depressed
            ><v-progress-circular
              :size="25"
              class="mr-2"
              indeterminate
            ></v-progress-circular>
            Loading</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";

export default {
  name: "downloadBatch",
  computed: {
    ...mapState({
      queue: (state) => state.report.queue,
    }),
  },
  data() {
    return {
      counter: 0,
      interv: null,
      timer: null,
      isDownloading: false,
      dialogDelete: false,
      idDelete: null,
      loading: false,
      rotate: 0,
      page: 1,
      limit: 50,
    };
  },
  mounted() {
    this.intervaled();
  },
  methods: {
    intervaled() {
      if (this.counter == 0) {
        this.fetchQueue();
        this.counter++;
      }
      if (this.counter > 0) {
        this.interv = setInterval(() => {
          if (this.$route.name == "download" && this.isDownloading) {
            this.fetchQueue();
          } else {
            clearInterval(this.interv);
          }
        }, 5000);
        let detik = 0;
        let menit = 0;
        setInterval(() => {
          if (this.isDownloading) {
            detik++;
            if (detik == 60) {
              menit++;
              detik = 0;
            }
            this.timer = `${menit > 9 ? "" : "0"}${menit}:${
              detik > 9 ? "" : "0"
            }${detik}`;
          }
        }, 1000);
      }
    },
    fetchQueue(type, number) {
      if (type == "reload") {
        document.getElementById(
          "reload"
        ).style.transform = `rotate(${(this.rotate =
          this.rotate + number)}deg)`;
        if (this.rotate == 720) {
          this.rotate = 0;
        }
      }
      this.$store
        .dispatch("report/getListDownload", {
          page: this.page,
          limit: this.limit,
        })
        .then((data) => {
          if (data) {
            data.data.forEach((item) => {
              if (item.status_archive == 0) {
                this.isDownloading = true;
              } else {
                this.isDownloading = false;
              }
            });
            console.log(`terhitung ${data.data.length} data terambil`);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    downloadZip(data) {
      location.href = data.full_path;
    },
    showModalDel(item) {
      this.idDelete = item.id;
      this.dialogDelete = true;
    },
    deleteQueue() {
      this.loading = true;
      let data = {
        id: this.idDelete,
      };
      this.$store
        .dispatch("report/delQueue", data)
        .then((data) => {
          this.fetchQueue();
          this.Swal("success", data.message);
          this.loading = false;
          this.dialogDelete = false;
        })
        .catch((err) => {
          this.loading = false;
          if (err.data) {
            this.Swal("error", err.data.response);
          } else {
            this.Swal("error", err.response.data.message);
          }
        });
    },
    Swal(type, msg) {
      Swal.fire({
        icon: type,
        title: msg,
        showConfirmButton: false,
        position: "top",
        toast: true,
        timer: 3000,
      });
    },
  },
};
</script>

<style scoped>
._100h {
  height: 100%;
}
.pill_items {
  background: #00897b;
  border-radius: 25px;
}
.pill_date {
  background: #e0f2f1;
  border-radius: 25px;
}
.n_items {
  background: #fff;
  border-radius: 50%;
  height: 40px;
  width: 40px;
}
#reload {
  transition: 0.5s;
}
</style>